import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './styles.css';

const Logging = ({ setCurrentUser, currentUser, setIsLogged, isLogged}) => {
  const [message, setMessage] = useState('');
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const fetchData = async () => {
    try {
      const response = await axios.get('https://xenabun.ru/api/roles.php');
      const jsonData = response.data;
      setRoles(jsonData);
    } catch (error) {
      console.error("Get-request error", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  async function register(operation, username, password, role) {
    const object = {
      operation: operation,
      username: username,
      password: password,
      role: role,
    };
    try {
      const response = await fetch('https://xenabun.ru/api/accounts.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(object),
      });
      const responseText = await response.text();
      const responseJSON = JSON.parse(responseText);
      if (responseJSON['status'] === false) {
        setIsLogged(false);
        setMessage(responseJSON['message']);
      } else {
        setIsLogged(true);
        if (localStorage.getItem('currentUser') !== username) {
          localStorage.setItem('currentUser', username);
          localStorage.setItem('isLogged', '1');
        }
        setCurrentUser(username);
        setMessage(responseJSON['message']);
        setInterval(2000);
        window.location.href = "/game";
      }
    } catch (error) {
      console.error('Ошибка', error);
    }
  };

  const [isRegistering, setIsRegistering] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState('');

  const handleLogin = async () => {
    await register("Log", username, password);
    if (isLogged === true){
      window.location.href = "/game";
    }
    else{
      console.log(message);
    }
  };

  const handleRegister = async () => {
    if (password === repeatPassword) {
      await register("Reg", username, password, role);
      await localStorage.setItem("Days", 1);
      window.location.href = "/game";
    } else {
      setMessage("Passwords don't match");
    }
  };

  const handleClearForm = () => {
    setUsername('');
    setPassword('');
    setRepeatPassword('');
  };

  const toggleRegister = () => {
    setIsRegistering(!isRegistering);
  };

  const styles = {
    container: {
      fontFamily: '"Arial", sans-serif',
      backgroundColor: '#f4f4f9',
      color: '#333',
      minHeight: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    content: {
      backgroundColor: '#fff',
      padding: '2rem',
      borderRadius: '8px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      width: '300px',
      textAlign: 'center',
    },
    title: {
      marginBottom: '1rem',
      color: '#6200ea',
    },
    message: {
      color: 'red',
      marginBottom: '1rem',
    },
    input: {
      width: '93%',
      padding: '0.5rem',
      margin: '0.5rem 0',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    select: {
      width: '100%',
      padding: '0.5rem',
      margin: '0.5rem 0',
      borderRadius: '4px',
      border: '1px solid #ccc',
    },
    button: {
      width: '100%',
      padding: '0.75rem',
      margin: '0.5rem 0',
      borderRadius: '4px',
      border: 'none',
      backgroundColor: '#6200ea',
      color: '#fff',
      cursor: 'pointer',
    },
    swapButton: {
      width: '100%',
      padding: '0.75rem',
      margin: '0.5rem 0',
      borderRadius: '4px',
      border: '1px solid #6200ea',
      backgroundColor: '#fff',
      color: '#6200ea',
      cursor: 'pointer',
    },
  };

  return (
    <>
      <div style={styles.container}>
        <div style={styles.content}>
          <h2 style={styles.title}>{isRegistering ? 'Sign Up' : 'Log In'}</h2>
          <p style={styles.message}>{message}</p>
          <input
            style={styles.input}
            type="text"
            placeholder="Login"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            style={styles.input}
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {isRegistering && (
            <>
              <input
                style={styles.input}
                type="password"
                placeholder="Repeat password"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
              />
              <select style={styles.select} onChange={(e) => setRole(e.target.value)}>
                <option value="">Choose your profession</option>
                {roles.map((role) => (
                  <option key={role.id} value={role.role_name}>{role.role_name}</option>
                ))}
              </select>
            </>
          )}
          <button style={styles.button} onClick={isRegistering ? handleRegister : handleLogin}>
            {isRegistering ? 'Sign Up' : 'Log in'}
          </button>
          <button style={styles.button} onClick={handleClearForm}>Clear Form</button>
          <button style={styles.swapButton} onClick={toggleRegister}>
            {isRegistering ? 'Logging' : 'Registration'}
          </button>
        </div>
      </div>
    </>
  );
};

export default Logging;
