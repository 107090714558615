import React from 'react';

const WorkHoursBar = ({ hours }) => {
  const bars = [];
  for (let i = 0; i < hours; i++) {
    bars.push(<div key={i} className="work-hour-bar"></div>);
  }

  return <div className="work-hours-container">{bars}</div>;
};

export default WorkHoursBar;
