import './App.css';
import React, { useState , useEffect} from 'react';
import Logging from './logging';
import './styles.css';
import {Routes, Route} from 'react-router-dom';
import MainPage from './MainPage';
import MainWindow from './MainWindow';

function App() {
  const [currentUser, setCurrentUser] = useState("");
  const [isLogged, setIsLogged] = useState(false);
  const [isLogging, setIsLogging] = useState(false);
  
  return (
    <>
    <Routes>
      <Route index element={<MainPage/>}/>
      <Route path="/auth" element={
        <Logging setCurrentUser={setCurrentUser}
        currentUser={currentUser}
        setIsLogged={setIsLogged}
        isLogged={isLogged}/>}
        />
      <Route path="/game" element={<MainWindow setCurrentUser={setCurrentUser} currentUser={currentUser}></MainWindow>}/>
    </Routes>
    </>
  );
}

export default App;
