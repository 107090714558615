import './App.css';
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import arrayShuffle from 'array-shuffle';
import Ranks from './ranks.json';
import './animation.scss';
import WorkHoursBar from './workHoursBar';

let counter = 0;
let comboMultiplier = 1;
let roleMultiplier = 1.35;
let percentage = 0;
const user = localStorage.getItem('currentUser');

function MainWindow() {
  const [tasks, setTasks] = useState([]);
  const [randomedTasks, setRandomedTasks] = useState([]);
  const [limitedTasks, setLimitedTasks] = useState([]);
  const [info, setInfo] = useState(null);
  const [expNeeded, setExpNeeded] = useState(100);
  const [LvL, setLvL] = useState(1);
  const [totalExp, setTotalExp] = useState(0);
  const [exp, setExp] = useState(0);
  const [workHours, setWorkHours] = useState(10);
  const [days, setDays] = useState(1);
  const [role, setRole] = useState("");
  const [randomNumbers, setRandomNumbers] = useState([]);
  const [achivements, setAchivements] = useState(Ranks);
  const [achivement, setAchivement] = useState();
  const [hint, setHint] = useState(false);
  const [loading, setLoading] = useState(false);
  const isFirstRender = useRef(true);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://xenabun.ru/api/tasks.php');
      const jsonData = response.data;
      setTasks(jsonData);
    } catch (error) {
      console.error("Get-request error", error);
    }
  };

  const importInfo = async (operation, username) => {
    try {
      const object = {
        operation: operation,
        username: username,
      };
      const response = await fetch('https://xenabun.ru/api/data.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(object),
      });
      const responseJSON = await response.json();
      setInfo(responseJSON);
    } catch (error) {
      console.error("Get-request error", error);
    }
  };

  const exportData = async (operation, username, lvl, totalExp) => {
    try {
      const object = {
        operation: operation,
        username: username,
        lvl: lvl,
        exp: totalExp,
      };
      const response = await fetch('https://xenabun.ru/api/data.php', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(object),
      });
      const responseJSON = await response.json();
      console.log(responseJSON);
    } catch (error) {
      console.error("Get-request error", error);
    }
  };

  useEffect(() => {
    if (loading) {
      const timer1 = setTimeout(() => {
        setLoading(false);
      }, 1500);

      const timer2 = setTimeout(() => {
        setLoading(true);
      }, 2000);

      return () => {
        clearTimeout(timer1);
        clearTimeout(timer2);
      };
    }
  }, [loading]);

  useEffect(() => {
    if (localStorage.getItem('currentUser') === 'emptyUserNameErr1019') {
      window.location.href = './';
    }
    fetchData();
  }, []);

  useEffect(() => {
    importInfo("import", user);
  }, [user]);

  useEffect(() => {
    if (info && info.status && info.data) {
      setRole(info.data.role);
      setTotalExp(info.data.exp);
      setExp(info.data.exp);
      newAchivementTaken();
      setDays(Number(localStorage.getItem('Days')));
      if (days <= 0) {
        setDays(1);
      }
    }
  }, [info]);

  useEffect(() => {
    taskLimiter();
    if (tasks && tasks.length > 0) {
      const initialRandomNumbers = tasks.map(() => Math.floor(Math.random() * 9) + 1);
      setRandomNumbers(initialRandomNumbers);
    }
  }, [tasks]);

  useEffect(() => {
    updateProgressBar(percentage, exp, expNeeded);
    LvLchanger();
  }, [exp]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    for (let i = 0; i < randomNumbers.length; i++) {
      if (workHours - randomNumbers[i] >= 0) {
        setHint(false);
        return;
      }
    }
    setHint(true);
  }, [workHours, randomNumbers]);

  const LvLchanger = async () => {
    if (exp >= expNeeded) {
      setLvL(LvL + 1);
      setExp(exp - expNeeded);
      setExpNeeded(Math.floor(expNeeded * 0.7 * Math.exp(0.7)));
      await newAchivementTaken();
    }
  };

  const clickHandler = (task, randomNum, index, task_tier) => {
    if (workHours < randomNum) {
      console.log("Недостаточно рабочих часов");
    } else {
      let trueExp = 0;
      if (task.task_role != 'any'){
        trueExp = (Math.floor(100 * task_tier * Math.exp(randomNumbers[index] / 10) * Math.exp(0.5 + Number(LvL / 100)) * comboMultiplier * roleMultiplier));
      }
      else{
        trueExp = (Math.floor(100 * task_tier * Math.exp(randomNumbers[index] / 10) * Math.exp(0.5 + Number(LvL / 100)) * comboMultiplier));
      }
      setTotalExp(totalExp + trueExp);
      setExp((prevExp) => prevExp + trueExp);
      setWorkHours((prevWorkHours) => prevWorkHours - randomNum);
      taskRandomizer();
      counter++;
    }
  };

  const nextDayHandler = async () => {
    await setLoading(true);
    await exportData("export", user, LvL, totalExp);
    await setWorkHours(10);
    await setDays((prevDays) => prevDays + 1);
    await localStorage.setItem("Days", days + 1);
    counter = 0;
  };

  const newAchivementTaken = () => {
    setAchivement(achivements[0][LvL]);
  };

  const taskLimiter = () => {
    const filteredTasks = tasks.filter(task => 
      (task.task_role.includes(role) || task.task_role.includes("any")) &&
      task.task_tier <= LvL &&
      task.task_tier >= LvL - 10
    );

    setLimitedTasks(filteredTasks);
  };

  useEffect(() => {
    taskLimiter();
  }, [tasks, role, LvL]);

  useEffect(() => {
    if (limitedTasks.length > 0) {
      taskRandomizer();
    }
  }, [limitedTasks]);
  
  const taskRandomizer = () => {
    const randomizedTasks = arrayShuffle(limitedTasks).slice(0, 5);
    setRandomedTasks(randomizedTasks);
  
    if (randomizedTasks.length > 0) {
      const initialRandomNumbers = randomizedTasks.map(() => Math.floor(Math.random() * 10) + 1);
      setRandomNumbers(initialRandomNumbers);
    }
  };

  function updateProgressBar(percentage, currentValue, maxValue) {
    const progressBar = document.getElementById('progress-bar');
    percentage = (currentValue / maxValue) * 100;
    progressBar.style.width = percentage + '%';
  }
  

  const formulateGain = (task, index, task_tier) => {
    let trueExp = 0;
    if (task.task_role != 'any'){
      trueExp = (Math.floor(100 * task_tier * Math.exp(randomNumbers[index] / 10) * Math.exp(0.5 + Number(LvL / 100)) * comboMultiplier * roleMultiplier));
    }
    else{
      trueExp = (Math.floor(100 * task_tier * Math.exp(randomNumbers[index] / 10) * Math.exp(0.5 + Number(LvL / 100)) * comboMultiplier));
    }
    return trueExp;
  }

  const formulateCombo = () => {
    comboMultiplier = 1.0 + counter / 10 * 2;
    return comboMultiplier;
  }

  const logOutHandler = () => {
    localStorage.setItem('currentUser', 'emptyUserNameErr1019');
    localStorage.setItem('isLogged', '0');
    window.location.href = './';
  }

  const styles = {
    container: {
      fontFamily: '"Arial", sans-serif',
      backgroundColor: '#f4f4f9',
      color: '#333',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    header: {
      backgroundColor: '#6200ea',
      color: '#fff',
      padding: '1rem',
      textAlign: 'center',
    },
    content: {
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      padding: '1rem',
    },
    statsBlock: {
      backgroundColor: '#e0e0e0',
      padding: '1rem',
      marginRight: '1rem',
      borderRadius: '8px',
      flexBasis: '30%',
    },
    tasksBlock: {
      flex: 1,
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
      gap: '1rem',
    },
    card: {
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '1rem',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flexGrow: 1,
    },    
    button: {
      padding: '0.5rem 1rem',
      backgroundColor: '#6200ea',
      color: '#fff',
      border: 'none',
      cursor: 'pointer',
      borderRadius: '4px',
      marginTop: '0.5rem',
      alignSelf: 'center',
    },
  };

  return (
    <div style={styles.container}>
      <>
        <div className={`overlay ${loading ? 'animate' : ''}`}>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
          <div className="bar"></div>
        </div>
      </>
      <header style={styles.header}>
        <h1>Profession Simulator</h1>
        <p className='pLogOut' onClick={() => logOutHandler()}>Log out</p>
      </header>
      <div style={styles.content}>
        <div style={styles.statsBlock}>
          <h2>User: {user}</h2>
          <h2>Level: {LvL}</h2>
          <h2>Current rank: {achivement}</h2>
          <div class="progress-container">
            <div class="progress-bar" id="progress-bar"></div>
          </div>
          <h6>Experience: {exp} / {expNeeded}</h6>
          <h2>Energy: </h2>
          <WorkHoursBar hours={workHours} />
          <h2>Working day №{days}</h2>
          <h3>Multiplier from taken cards: {formulateCombo()}</h3>
          <button
            className='nextDay'
            style={styles.button}
            onClick={() => nextDayHandler()}
          >
            Next Day
          </button>
        </div>
        <div style={styles.tasksBlock}>
          {randomedTasks && randomNumbers.length > 0 && randomedTasks.map((task, index) => (
            <div key={task.id} style={styles.card} className={task.task_role !== 'any' ? 'specialcard' : ''}>
              <div style={styles.cardContent} className={task.task_role !== 'any' ? 'specialcard' : ''}>
                <div className={task.task_role != 'any' ? 'specialcard' : ''}>
                <h3>{task.task_name}</h3>
                <p>{task.task_description}</p>
                </div>
                <div className={task.task_role !== 'any' ? 'specialcard' : ''}>
                <p>Tier: {task.task_tier}</p>
                <p>Experience: {formulateGain(task, index, task.task_tier)}</p>
                <p>Energy: </p>
                <WorkHoursBar hours={randomNumbers[index]} />
                <p>Role: {task.task_role}</p>
                </div>
              </div>
              <button
                style={styles.button}
                onClick={() => clickHandler(task, randomNumbers[index], index, task.task_tier)}
              >
                Action
              </button>
            </div>
          ))}
        </div>
      </div>
      <footer style={styles.header}>
        <p>&copy; Profession Simulator</p>
      </footer>
      {hint &&
        <>
          <div className='blur-background'>
            <div className='hint'>
              <h2>Hint: Next Day</h2>
              <h3>You've run out of working time or there are no more tasks to complete today.</h3>
              <button
                className='nextDay'
                style={styles.button}
                onClick={() => nextDayHandler()}
              >
                Next Day
              </button>
            </div>
          </div>
        </>
      }
    </div>
  );
}

export default MainWindow;
