import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';

const professions = [
    "Accountant", "Actor", "Architect", "Artist", "Baker",
    "Banker", "Bartender", "Biologist", "Carpenter", "Chef",
    "Chemist", "Cleaner", "Coach", "Dentist", "Designer",
    "Developer", "Doctor", "Driver", "Economist", "Electrician",
    "Engineer", "Farmer", "Firefighter", "Gardener", "Hairdresser",
    "Journalist", "Judge", "Lawyer", "Librarian", "Mechanic",
    "Musician", "Nurse", "Painter", "Pharmacist", "Photographer",
    "Pilot", "Plumber", "Police Officer", "Professor", "Programmer",
    "Receptionist", "Scientist", "Secretary", "Singer", "Teacher",
    "Translator", "Veterinarian", "Waiter", "Writer"
];

const getRandomYPosition = () => {
  return Math.floor(Math.random() * window.innerHeight);
};

const generateRandomProfession = () => {
  const index = Math.floor(Math.random() * professions.length);
  return professions[index];
};

function MainPage() {
  const [words, setWords] = useState([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newWord = {
        id: Date.now(),
        text: generateRandomProfession(),
        yPosition: getRandomYPosition(),
      };
      setWords((prevWords) => [...prevWords, newWord]);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="MainPage">
      <div className="menu">
        <h1>Welcome to my Professional Simulator</h1>
        <h2>Study the activities of professions playfully</h2>
        <Link to="/auth">
          <button className="startButton">Get started!</button>
        </Link>
      </div>
      <div className="background">
        {words.map((word) => (
          <span
            key={word.id}
            className="profession"
            style={{ top: `${word.yPosition}px` }}
          >
            {word.text}
          </span>
        ))}
      </div>
    </div>
  );
}

export default MainPage;
